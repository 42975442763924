@use 'sass:map';
@use 'sass:math';

@use '@shared/styles/config/variables' as *;
@use '@shared/styles/mixins/breakpoint' as *;
@use '@shared/styles/mixins/transition' as *;
@use '@shared/styles/mixins/spacing' as *;

.root {
  margin-top: map.get($spacers, 'spacing', '4');
  overflow: hidden;
}

.intro {
  text-align: center;
}

.title {
  @include spacing('md', 'bottom', 'margin');
}

.chip {
  @include spacing('md', 'bottom', 'margin');
}

.mobile {
  padding: 0 map.get($spacers, 'spacing', '5');
  position: relative;

  &:after {
    content: '';
    display: block;
    padding-bottom: 100%;
  }

  img {
    display: block;
    width: 100%;
    height: 100%;
    max-height: 42.5rem;
    object-fit: contain;
    position: absolute;
    left: 0;
    top: 0;
  }

  @include breakpoint('lg', 'min') {
    display: none;
  }
}

.main {
  position: relative;

  @include breakpoint('md', 'min') {
    display: block;
  }

  @include breakpoint('lg', 'min') {
    min-height: 42.5rem;
  }
}

.button {
  align-items: center;
  appearance: none;
  backdrop-filter: blur(map.get($spacers, 'spacing', '4'));
  background-color: rgba(map.get($colors, 'lily-of-the-valley'), 0.5);
  border-radius: map.get($radiuses, '2');
  border: 0;
  box-shadow: map.get($shadows, '2');
  color: map.get($colors, 'charcoal-charlie');
  cursor: pointer;
  display: flex;
  height: 3rem;
  justify-content: center;
  opacity: 0;
  padding: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 3rem;
  z-index: 1;

  &:first-of-type {
    left: map.get($spacers, 'spacing', '4');
  }

  &:last-of-type {
    right: map.get($spacers, 'spacing', '4');
  }

  &:hover {
    background-color: map.get($colors, 'purple-rain');
    color: map.get($colors, 'white');
  }

  &:active {
    transform: translateY(calc(-50% + map.get($spacers, 'spacing', '1')));
  }
}

.links {
  display: flex;
  justify-content: center;

  ul {
    justify-content: center;
  }
}

.introWrapper {
  background-color: map.get($colors, 'white');
  z-index: 5;

  @include breakpoint('lg', 'min') {
    border-radius: map.get($spacers, 'spacing', '12') * 3;
    padding: map.get($spacers, 'spacing', '8');
    margin-bottom: -10rem;
  }

  @include breakpoint('lg', 'max') {
    border-radius: map.get($spacers, 'spacing', '4');
    padding: map.get($spacers, 'spacing', '5');
  }

  .text {
    @include breakpoint('md', 'min') {
      max-width: 65%;
      margin: 0 auto;
    }
  }
}

.caption {
  display: flex;
  align-items: center;
  justify-content: center;
  color: map.get($colors, 'white');
  z-index: 5;

  [class*='Button_'] {
    color: map.get($colors, 'white');
  }

  @include breakpoint('md', 'max') {
    display: none;
  }
}

.container {
  margin-left: auto;
  margin-right: auto;
  padding: 0 map.get($spacers, 'spacing', '4');

  @each $key, $breakpoint in map.get($widths, 'breakpoint') {
    @include breakpoint(#{$key}, 'min') {
      max-width: map.get(map.get($widths, 'container'), $key);
    }
  }

  @include breakpoint('xl', 'max') {
    max-width: 100%;
  }

  @media (min-width: 75rem) {
    max-width: 110rem;
  }
}

.staticImage {
  max-height: 42.5rem;
  margin: 0;
  position: relative;

  @include breakpoint('lg', 'min') {
    min-height: 42.5rem;
  }

  @include breakpoint('lg', 'max') {
    display: none;
  }

  .imageWrapper {
    overflow: hidden;
    position: relative;
    border-radius: map.get($radiuses, '4');

    img {
      width: 100%;
      object-fit: cover;
      min-height: 42.5rem;
    }

    @include breakpoint('lg', 'min') {
      min-height: 42.5rem;
    }
  }

  [class*='Slider_feature'] {
    -webkit-animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  }

  .feature1 {
    animation-delay: 0.75s;
  }

  .feature1 {
    animation-delay: 1s;
  }
}

.feature {
  position: absolute;
  max-width: 15rem;
  -webkit-animation: slide-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;

  @include breakpoint('md', 'max') {
    display: none;
  }

  img {
    height: auto;
    max-width: 100%;
  }

  a {
    margin-bottom: map.get($spacers, 'spacing', '4');
    border-radius: map.get($spacers, 'spacing', '8');

    align-self: flex-start;
    display: inline-flex;
    gap: 0.5rem;

    &.iconRight {
      flex-direction: row-reverse;
    }
  }

  &.imageAbove {
    display: flex;
    flex-direction: column-reverse;

    img {
      margin-bottom: map.get($spacers, 'spacing', '4');
    }
  }
}

@-webkit-keyframes slide-top {
  0% {
    -webkit-transform: translateY(30rem);
    transform: translateY(30rem);
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes slide-top {
  0% {
    -webkit-transform: translateY(30rem);
    transform: translateY(30rem);
  }

  100% {
    -webkit-transform: translateY(-0);
    transform: translateY(0);
  }
}

@-webkit-keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  100% {
    -webkit-transform: translateY(30rem);
    transform: translateY(30rem);
  }
}

@keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  100% {
    -webkit-transform: translateY(30rem);
    transform: translateY(30rem);
  }
}

.captionWrapper {
  position: absolute;
  left: 50%;
  bottom: map.get($spacers, 'spacing', '5');
  transform: translateX(-50%);
  display: none;
  flex-direction: column;
  align-items: center;
  gap: map.get($spacers, 'spacing', '4');
  padding: map.get($spacers, 'spacing', '4');
  background-color: rgba(map.get($colors, 'mirage'), 0.5);
  border-radius: map.get($radiuses, '2');
  color: map.get($colors, 'white');
  z-index: 100;

  @include breakpoint('lg', 'min') {
    display: flex;
  }
}